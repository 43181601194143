import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'


import {
	Flex,
	Image,
	Heading
} from 'reakit'

import { Container, PhoneBody } from '../../../components/layouts'

import {
	Loader,
	CategoryScroll,
	DiscoverCard,
} from '../../../components/ui'

import {
	getDiscover, getCategories, setActiveOrg
} from '../../../factory'

import Layout from '../../../components/layout'

class IndexPage extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			category_id: null
		}

		this.selectCategory = this.selectCategory.bind(this)
		this.selectOrganization = this.selectOrganization.bind(this)
	}

	componentDidMount() {
		this.props.getDiscover()
		this.props.getCategories()
	}

	selectCategory(category_id) {
		this.setState({category_id})
		this.props.getDiscover({category_id})
	}

	selectOrganization(organization) {
		this.setState({organization})
		this.props.setActiveOrg(organization)
		navigate(`/admin/discover/organization/?oid=${organization.organization_id}`)
	}

	render() {
		const {organizations, categories, pending} = this.props

		const primary = organizations && organizations.filter(org => org.is_primary_featured)
		const featured = organizations && organizations.filter(org => org.is_featured && !org.is_primary_featured )
		const rest = organizations && organizations.filter(org => !org.is_featured && !org.is_primary_featured)

		return (
			<Layout>
				<Container>
					<PhoneBody title={'Discover'} style={{flex: 1, justifyContent: 'center'}}>
						{categories.length > 0 && <CategoryScroll
							onClickCategory={this.selectCategory}
							categories={categories}
							selected={this.state.category_id}
						/>}
						{pending &&
						<Flex style={{width: '100%', height: 400, justifyContent: 'center', alignItems: 'center'}}>
							<Loader/></Flex>}
						{primary.length > 0 &&
							<Flex style={{width: '100%', alignItems: 'center', flex: 1, justifyContent: 'center', marginTop: 15}}>
								<DiscoverCard
									onSelectOrganization={this.selectOrganization}
									organization={primary[0]} primary/>
							</Flex>
						}
						{
							featured.length > 0 &&
							<Flex style={{width: '100%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 15}}>
								<Flex style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', width: '100%'}}>
									{featured.map((item) => (<DiscoverCard
										onSelectOrganization={this.selectOrganization}
										organization={item}/>))}
								</Flex>
							</Flex>


						}
						{(featured.length > 0 || primary.length > 0) &&
						<div style={{textAlign: 'center'}}>
							<Image
								src={"https://storage.googleapis.com/resources.flourishchange.com/Marketing/Illustrations/PNGs/Onboarding1.png"}
								height={'auto'}
								width={100}
								margin={'auto'}
							/>
							<Heading as="h1" fontSize="18px">Looking for your organization?</Heading>
						</div>
						}
						{
							rest.length > 0 &&
							<Flex style={{width: '100%', alignContent: 'center', alignItems: 'center', justifyContent: 'center', marginTop: 15 }}>
								<Flex style={{flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start'}}>
									{rest.map((item) => (<DiscoverCard
									 onSelectOrganization={this.selectOrganization}
									 organization={item}/>))}
								</Flex>
							</Flex>
						}
					</PhoneBody>
				</Container>
			</Layout>
		)
	}
}

IndexPage.propTypes = {
	organizations: PropTypes.array,
	getDiscover: PropTypes.func,
	getCategories: PropTypes.func,
	categories: PropTypes.array,
	setActiveOrg: PropTypes.func,
	pending: PropTypes.bool
}

const mapStateToProps = (state) => {
	return {
		user: state.userState.user,
		organizations: state.discoverState.organizations,
		pending: state.discoverState.pending,
		categories: state.discoverState.categories,
		categoryPending: state.discoverState.categoryPending
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		getDiscover: (data) => dispatch(getDiscover(data)),
		getCategories: () => dispatch(getCategories()),
		setActiveOrg: (org) => dispatch(setActiveOrg(org))
	}
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(IndexPage)
